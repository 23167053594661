<template>
  <div :class="setClass()">
    <label for="file"><span>파일 업로드</span></label>
    <input
      id="file"
      type="file"
      @click="imageInit"
      @change="imageUpload"
      accept=".jpg, .jpeg, .png, .gif, .svg"
      multiple
      :class="{ga_a_204: $router.currentRoute.name === 'image'}"
    />
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {checkFiles, checkFile} from "@/utils/file.js";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");

export default {
  props: ["fileUsage"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    // class binding
    setClass() {
      if (this.$router.currentRoute.name === "image") {
        return this.$style.userFileUpload;
      } else {
        return this.$style.fileUpload;
      }
    },
    // 이미지 업로드 초기화
    imageInit(e) {
      e.target.value = null;
    },
    // 이미지 업로드
    imageUpload(e) {
      let files = e.target.files;

      let validFiles = checkFiles(files, this.fileUsage);
      if (!validFiles.result) {
        if (this.$router.currentRoute.name === "image") {
          this.setAlert({
            isVisible: true,
            message: validFiles.msg
          });
        } else {
          this.setConfirm({
            isVisible: true,
            message: validFiles.msg,
            messageBtn: `이미지 관리 바로가기`,
            event: () => window.open("/admin/image")
          });
        }
        return;
      }
      let result = true;
      for (let file of files) {
        if (file) {
          let validFile = checkFile(file);
          if (!validFile.result) {
            this.setAlert({
              isVisible: true,
              message: validFile.msg
            });
            result = false;
            return;
          }
        }
      }

      if (result) {
        this.$emit("imagesAdd", files);
        for (let file of files) {
          if (file) {
            this.$emit("imageAdd", file);
          }
        }
      }
    },

    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleConfirm.mapMutations(["setConfirm"])
  },
  mounted() {}
};
</script>

<style module lang="scss">
.fileUpload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 187px;
  margin-bottom: 16px;

  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
  label {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f7f9fc;
    background-image: url($path + "ico_upload.svg");
    background-repeat: no-repeat;
    background-position: center calc(50% - 20px);
    border: 1px dashed #acaeb2;
    color: #acaeb2;
    text-align: center;

    span {
      top: 100px;
      padding-left: 0px;
    }
  }
}
.userFileUpload {
  position: relative;

  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
  label {
    display: block;
    height: 30px;
    color: #6630b3;
    font-size: 12px;
    border: 1px solid #6630b3;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    line-height: 30px;
    text-align: center;

    span {
      padding-left: 20px;
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-image: url($path + "ico_upload_active.svg");
      display: inline-block;
    }
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .userFileUpload {
    margin-left: 16px;

    label {
      width: 120px;
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .userFileUpload {
    margin-top: 16px;

    label {
      width: 100%;
    }
  }
}
</style>
