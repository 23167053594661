"use strict";

const FileLimit = 1024 * 1024 * 2;
const FilesLimit = 1024 * 1024 * 50;

export function checkFiles(files, fileUsage) {
  try {
    let fileLimit = FilesLimit - fileUsage;

    let fileSum = 0;
    for (let file of files) {
      fileSum += file.size;
    }

    if (fileSum > fileLimit) {
      throw `저장 공간을 초과하여 이미지를 업로드할 수 없습니다.<br />사용하지 않는 이미지를 삭제 후 다시 업로드 해주세요.`;
    }
  } catch (e) {
    return {result: false, msg: e};
  }

  return {result: true};
}

export function checkFile(file) {
  try {
    if (file.size > FileLimit) {
      throw `2MB 이하 파일만 업로드 할 수 있습니다. 선택한 파일 용량을 확인해주세요.`;
    }

    if (!/\.(jpg|jpeg|png|gif|svg)$/i.test(file.name)) {
      throw `JPG, JPEG, PNG, SVG, GIF 확장자 파일만 업로드 할 수 있습니다.`;
    }
  } catch (e) {
    return {result: false, msg: e};
  }

  return {result: true};
}
