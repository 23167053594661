var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.setClass()
  }, [_vm._m(0), _c('input', {
    class: {
      ga_a_204: _vm.$router.currentRoute.name === 'image'
    },
    attrs: {
      "id": "file",
      "type": "file",
      "accept": ".jpg, .jpeg, .png, .gif, .svg",
      "multiple": ""
    },
    on: {
      "click": _vm.imageInit,
      "change": _vm.imageUpload
    }
  })]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "file"
    }
  }, [_c('span', [_vm._v("파일 업로드")])]);
}]

export { render, staticRenderFns }